import _ from 'lodash'

const name = 'CommonConfigAspect'

export const defaultModel = {
    commonConfig: {}
}

const functionLibrary = {
    sendPlatformUpdates: (newCommonConfig, sendPlatformCommonConfigUpdates) => {
        if (!_.isEmpty(newCommonConfig)) {
            sendPlatformCommonConfigUpdates(newCommonConfig)
        }
    }
}

export {name, functionLibrary}
